<template>
  <div class="step-2">
    <v-container class="pa-0 mt-sm-4 mt-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-form ref="formStep2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <h3 class="heading-h3">Registration</h3>
                <!-- Banking & Employment -->
                <p class="b-body-1 mt-1">
                  Step 2 of 3:
                  <span class="b-subtitle-1">Banking & Employment</span>
                </p>

                <div class="d-flex">
                  <v-card
                    color="primary"
                    width="154.67"
                    height="4"
                    elevation="0"
                  >
                  </v-card>
                  <v-card
                    class="ml-2"
                    color="primary"
                    width="154.67"
                    height="4"
                    elevation="0"
                  >
                  </v-card>
                  <v-card
                    class="ml-2"
                    color="gray400"
                    width="154.67"
                    height="4"
                    elevation="0"
                  >
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" lg="4">
                <v-row>
                  <!-- Payment information	 -->
                  <v-col cols="12">
                    <h5 class="heading-h4">Payment information</h5>
                  </v-col>

                  <!-- Bank -->
                  <v-col class="mt-n3" cols="12">
                    <v-card width="362" flat>
                      <p class="label-text mb-1">Bank</p>

                      <v-select
                        v-model="paymentInformation.bank"
                        :rules="paymentInformation.bankRules"
                        :items="getBanksList"
                        placeholder="Select..."
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      >
                      </v-select>
                    </v-card>
                  </v-col>

                  <!-- Account type -->
                  <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">Account type</p>

                      <v-radio-group
                        v-model="paymentInformation.typeOfAccount"
                        :disabled="isDisabled"
                        class="mt-n1"
                      >
                        <v-radio
                          v-for="accountType in accountTypes"
                          :key="accountType"
                          :label="`${accountType}`"
                          :value="accountType"
                        ></v-radio>
                      </v-radio-group>
                    </v-card>
                  </v-col>

                  <!-- Account number -->
                  <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">Account number</p>

                      <v-text-field
                        v-model="paymentInformation.bankAccountNumber"
                        :rules="paymentInformation.bankAccountNumberRules"
                        :disabled="isDisabled"
                        class="inputPrice"
                        type="number"
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!-- Employment -->
                  <v-col cols="12">
                    <h5 class="heading-h4 mt-4">Employment</h5>
                  </v-col>

                  <!-- Employment status -->
                  <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">Employment status</p>

                      <v-select
                        v-model="additionalInformation.employmentStatus"
                        :rules="additionalInformation.employmentStatusRules"
                        :items="getEmploymentTypesList"
                        :disabled="isDisabled"
                        placeholder="Select..."
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      >
                      </v-select>
                      <p
                        v-if="
                          additionalInformation.employmentStatus == 'Contract'
                        "
                      >
                        Please note that we require a copy of your work contract
                        clearly stating the start and finish date of the
                        contract. Please upload the contract together with your
                        payslip to ensure your application can be processed
                      </p>
                    </v-card>
                  </v-col>

                  <div
                    v-if="
                      additionalInformation.employmentStatus == 'Full-time' ||
                      additionalInformation.employmentStatus == 'Contract'
                    "
                  >
                    <!-- Salary day -->
                    <v-col cols="12">
                      <v-card class="mt-n6" max-width="170" flat>
                        <p class="label-text mb-1">Salary day</p>

                        <v-select
                          v-model="additionalInformation.nextIncomeDate.day"
                          :rules="additionalInformation.nextIncomeDate.dayRules"
                          :items="getDaysList"
                          placeholder="Select..."
                          height="48px"
                          full-width
                          outlined
                          dense
                          required
                        >
                        </v-select>
                      </v-card>
                    </v-col>

                    <!--  Employer name -->
                    <v-col cols="12">
                      <v-card class="mt-n4" width="362" flat>
                        <p class="label-text mb-1">Employer name</p>

                        <v-text-field
                          v-model="additionalInformation.employerName"
                          :rules="additionalInformation.employerNameRules"
                          height="48px"
                          full-width
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-card>
                    </v-col>

                    <!--  Position -->
                    <v-col cols="12">
                      <v-card class="mt-n4" width="362" flat>
                        <p class="label-text mb-1">Position</p>

                        <v-text-field
                          v-model="additionalInformation.position"
                          :rules="additionalInformation.positionRules"
                          height="48px"
                          full-width
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-card>
                    </v-col>

                    <!--  Work phone -->
                    <v-col cols="12">
                      <v-card class="mt-n4" width="362" flat>
                        <p class="label-text mb-1">Work phone</p>

                        <v-text-field
                          v-model="additionalInformation.workPhone"
                          :rules="additionalInformation.workPhoneRules"
                          maxlength="12"
                          height="48px"
                          full-width
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-card>
                    </v-col>
                  </div>

                  <!-- Next -->
                  <!-- Desktop -->
                  <v-col cols="12" class="hidden-xs-only mt-n2">
                    <v-btn
                      v-if="readyToSubmit"
                      @click="validateFormStep2"
                      class="b-button primary text-capitalize"
                      height="48"
                      width="175"
                      elevation="0"
                    >
                      Next
                    </v-btn>

                    <v-btn
                      v-else
                      @click="validateFormStep2"
                      class="b-button gray500 text-capitalize"
                      height="48"
                      width="175"
                      elevation="0"
                      disabled
                    >
                      Next
                    </v-btn>
                  </v-col>

                  <!-- Mobile -->
                  <v-col cols="12" class="hidden-sm-and-up mt-n2">
                    <v-btn
                      v-if="readyToSubmit"
                      @click="validateFormStep2"
                      class="b-button primary text-capitalize"
                      height="48"
                      width="100%"
                      elevation="0"
                    >
                      Next
                    </v-btn>

                    <v-btn
                      v-else
                      @click="validateFormStep2"
                      class="b-button gray500 text-capitalize"
                      height="48"
                      width="100%"
                      elevation="0"
                      disabled
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <!-- Overlay -->
          <v-overlay :value="overlay" z-index="1000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "LimeLoansStep2",

  data() {
    return {
      overlay: false,
      readyToSubmit: false,

      employmentDetailsWasSend: false,
      bankingDetailsWasSend: false,
      verifyBankAccountHolderWasSend: true,
      isDisabled: false,

      // Payment Information
      paymentInformation: {
        // Bank
        bank: "",
        bankRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.paymentInformation.bankHasError) ||
            this.paymentInformation.bankErrorMessage,
          (v) =>
            v !== "Other" ||
            "Unfortunately we currently do not provide loans for customers using other banks",
        ],
        bankHasError: false,
        bankErrorMessage: "bank",

        // Type Of Account
        typeOfAccount: "",
        typeOfAccountRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.paymentInformation.typeOfAccountHasError) ||
            this.paymentInformation.typeOfAccountErrorMessage,
        ],
        typeOfAccountHasError: false,
        typeOfAccountErrorMessage: "type of account",

        // Bank Account Number
        bankAccountNumber: "",
        bankAccountNumberRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.paymentInformation.bankAccountNumberHasError) ||
            this.paymentInformation.bankAccountNumberErrorMessage,
          (v) => /^[0-9]+$/.test(v) || "Only 0 to 9 is allowed",
        ],
        bankAccountNumberHasError: false,
        bankAccountNumberErrorMessage: "bank account number",
      },

      accountTypes: ["Savings", "Current"],

      // Additional Information
      additionalInformation: {
        // Employment Status
        employmentStatus: "",
        employmentStatusRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.additionalInformation.employmentStatusHasError) ||
            this.additionalInformation.employmentStatusErrorMessage,
          (v) =>
            v == "Full-time" ||
            v == "Contract" ||
            "Currently this employment status does not qualify for our services",
        ],
        employmentStatusHasError: false,
        employmentStatusErrorMessage: "employment status",

        // Next Income Date
        nextIncomeDate: {
          // Day
          day: null,
          dayRules: [
            (v) => !!v || "Required",
            (v) =>
              (v && !this.additionalInformation.nextIncomeDate.dayHasError) ||
              this.additionalInformation.nextIncomeDate.dayErrorMessage,
          ],
          dayHasError: false,
          dayErrorMessage: "day",
        },

        // The Emergency Phone Number
        theEmergencyPhoneNumber: "",
        theEmergencyPhoneNumberRules: [
          (v) => !!v || "Required",
          (v) => v.length == 12 || "Must be 10 numbers",
          (v) =>
            (v &&
              !this.additionalInformation.theEmergencyPhoneNumberHasError) ||
            this.additionalInformation.theEmergencyPhoneNumberErrorMessage,
          (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
        ],
        theEmergencyPhoneNumberHasError: false,
        theEmergencyPhoneNumberErrorMessage: "the emergency phone number",

        // Years Of Employment
        yearsOfEmployment: "",
        yearsOfEmploymentRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.additionalInformation.yearsOfEmploymentHasError) ||
            this.additionalInformation.yearsOfEmploymentErrorMessage,
        ],
        yearsOfEmploymentHasError: false,
        yearsOfEmploymentErrorMessage: "years of employment",

        // Month Of Employment
        monthOfEmployment: "",
        monthOfEmploymentRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.additionalInformation.monthOfEmploymentHasError) ||
            this.additionalInformation.monthOfEmploymentErrorMessage,
        ],
        monthOfEmploymentHasError: false,
        monthOfEmploymentErrorMessage: "month of employment",

        // Position
        position: "",
        positionRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.additionalInformation.positionHasError) ||
            this.additionalInformation.positionErrorMessage,
          (v) =>
            /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
            "Only english letters, apostrophes and hyphens are allowed",
          (v) => v.length <= 50 || "Can not be more than 50 characters",
        ],
        positionHasError: false,
        positionErrorMessage: "position",

        // Employer Name
        employerName: "",
        employerNameRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.additionalInformation.employerNameHasError) ||
            this.additionalInformation.employerNameErrorMessage,
          (v) =>
            /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
            "Only english letters, apostrophes and hyphens are allowed",
          (v) => v.length <= 100 || "Can not be more than 100 characters",
        ],
        employerNameHasError: false,
        employerNameErrorMessage: "employer name",

        // Work Phone
        workPhone: "",
        workPhoneRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.additionalInformation.workPhoneHasError) ||
            this.additionalInformation.workPhoneErrorMessage,
          (v) => /^[0-9+\s]+$/.test(v) || "Only 0 to 9 is allowed",
          (v) => v.length == 12 || "Must be 10 numbers",
        ],
        workPhoneHasError: false,
        workPhoneErrorMessage: "work phone",

        // Frequency Of Income
        frequencyOfIncome: "monthly",
        frequencyOfIncomeRules: [
          (v) => !!v || "Required",
          (v) =>
            (v && !this.additionalInformation.frequencyOfIncomeHasError) ||
            this.additionalInformation.frequencyOfIncomeErrorMessage,
        ],
        frequencyOfIncomeHasError: false,
        frequencyOfIncomeErrorMessage: "frequency of income",
      },
    };
  },

  computed: {
    ...mapGetters({
      getBanksList: "list/getBanksList",
      getBankAccountTypesList: "list/getBankAccountTypesList",
      getEmploymentTypesList: "list/getEmploymentTypesList",
      getDaysList: "list/getDaysList",
    }),
  },

  created() {
    this.$helpers.resetErrorHandlerState();
  },

  watch: {
    // paymentInformation.bank
    "paymentInformation.bank"(val) {
      if (this.paymentInformation.bankHasError) {
        this.paymentInformation.bankHasError = false;
        this.paymentInformation.bankErrorMessage = "";
      }

      this.setIsDisabled(val);

      this.setReadyToSubmit();
    },

    // paymentInformation.typeOfAccount
    "paymentInformation.typeOfAccount"() {
      if (this.paymentInformation.typeOfAccountHasError) {
        this.paymentInformation.typeOfAccountHasError = false;
        this.paymentInformation.typeOfAccountErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // paymentInformation.bankAccountNumber
    "paymentInformation.bankAccountNumber"() {
      if (this.paymentInformation.bankAccountNumberHasError) {
        this.paymentInformation.bankAccountNumberHasError = false;
        this.paymentInformation.bankAccountNumberErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // additionalInformation.employmentStatus
    "additionalInformation.employmentStatus"() {
      if (this.additionalInformation.employmentStatusHasError) {
        this.additionalInformation.employmentStatusHasError = false;
        this.additionalInformation.employmentStatusErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // additionalInformation.nextIncomeDate.day
    "additionalInformation.nextIncomeDate.day"() {
      if (this.additionalInformation.nextIncomeDate.dayHasError) {
        this.additionalInformation.nextIncomeDate.dayHasError = false;
        this.additionalInformation.nextIncomeDate.dayErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // additionalInformation.theEmergencyPhoneNumber
    "additionalInformation.theEmergencyPhoneNumber"() {
      if (this.additionalInformation.theEmergencyPhoneNumberHasError) {
        this.additionalInformation.theEmergencyPhoneNumberHasError = false;
        this.additionalInformation.theEmergencyPhoneNumberErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // additionalInformation.yearsOfEmployment
    "additionalInformation.yearsOfEmployment"() {
      if (this.additionalInformation.yearsOfEmploymentHasError) {
        this.additionalInformation.yearsOfEmploymentHasError = false;
        this.additionalInformation.yearsOfEmploymentErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // additionalInformation.monthOfEmployment
    "additionalInformation.monthOfEmployment"() {
      if (this.additionalInformation.monthOfEmploymentHasError) {
        this.additionalInformation.monthOfEmploymentHasError = false;
        this.additionalInformation.monthOfEmploymentErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // additionalInformation.position
    "additionalInformation.position"() {
      if (this.additionalInformation.positionHasError) {
        this.additionalInformation.positionHasError = false;
        this.additionalInformation.positionErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // additionalInformation.employerName
    "additionalInformation.employerName"() {
      if (this.additionalInformation.employerNameHasError) {
        this.additionalInformation.employerNameHasError = false;
        this.additionalInformation.employerNameErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // additionalInformation.workPhone
    "additionalInformation.workPhone"(val) {
      if (this.additionalInformation.workPhoneHasError) {
        this.additionalInformation.workPhoneHasError = false;
        this.additionalInformation.workPhoneErrorMessage = "";
      }

      this.additionalInformation.workPhone =
        this.$helpers.formatInputMobileNumber(val);

      this.setReadyToSubmit();
    },

    // additionalInformation.frequencyOfIncome
    "additionalInformation.frequencyOfIncome"() {
      if (this.additionalInformation.frequencyOfIncomeHasError) {
        this.additionalInformation.frequencyOfIncomeHasError = false;
        this.additionalInformation.frequencyOfIncomeErrorMessage = "";
      }

      this.setReadyToSubmit();
    },
  },

  mounted() {},

  methods: {
    ...mapActions({
      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    ...mapGetters({
      getIdNumber: "auth/getIdNumber",
      getSessionKey: "auth/getSessionKey",
      getSessionExpires: "auth/getSessionExpires",
      getCurrentState: "registration/getCurrentState",
    }),

    ...mapMutations({
      setLoggedIn: "auth/setLoggedIn",
      setSessionKey: "auth/setSessionKey",
      setSessionExpires: "auth/setSessionExpires",
      setUser: "auth/setUser",
      setIdNumber: "auth/setIdNumber",

      setCurrentState: "registration/setCurrentState",
    }),

    // validateFormStep2
    validateFormStep2() {
      this.$refs.formStep2.validate();

      if (this.$refs.formStep2.validate()) {
        this.overlay = true;

        this.employmentDetailsWasSend = false;
        this.bankingDetailsWasSend = false;
        this.verifyBankAccountHolderWasSend = true;

        if (
          !this.bankingDetailsWasSend ||
          !this.verifyBankAccountHolderWasSend
        ) {
          this.bankingDetails();
        }

        if (!this.employmentDetailsWasSend) {
          this.sendEmploymentDetails();
        }

        // this.validate();

        // this.overlay = false;
      }
    },

    // complete step 2
    completeStep2() {
      if (this.bankingDetailsWasSend && this.employmentDetailsWasSend) {
        /* if (
          this.paymentInformation.bank == "Standard Bank" &&
          this.paymentInformation.bankAccountNumber == "123456789"
        ) {
          this.getApiUserDetails();

          this.validate();
        } else  */

        if (this.verifyBankAccountHolderWasSend) {
          this.getApiUserDetails();

          this.validate();
        }

        this.overlay = false;
      }
    },

    validate() {
      this.setCurrentState("step3");

      if (this.$gtm.enabled()) {
        window.dataLayer?.push({
          event: "registration",
          eventParam: "second_step_done",
        });
      }

      this.$vuetify.goTo(0);
    },

    // setReadyToSubmit
    setReadyToSubmit() {
      if (
        this.paymentInformation.bank === "" ||
        this.paymentInformation.bank === null ||
        this.paymentInformation.typeOfAccount === "" ||
        this.paymentInformation.typeOfAccount === null ||
        this.paymentInformation.bankAccountNumber === "" ||
        this.paymentInformation.bankAccountNumber === null ||
        this.additionalInformation.employmentStatus === "" ||
        this.additionalInformation.employmentStatus === null ||
        this.additionalInformation.nextIncomeDate.day === "" ||
        this.additionalInformation.nextIncomeDate.day === null ||
        this.additionalInformation.employerName === "" ||
        this.additionalInformation.employerName === null ||
        this.additionalInformation.position === "" ||
        this.additionalInformation.position === null ||
        this.additionalInformation.workPhone === "" ||
        this.additionalInformation.workPhone === null ||
        !this.$refs.formStep2.validate()
      ) {
        this.readyToSubmit = false;
      } else {
        this.readyToSubmit = true;
      }
    },

    // setIsDisabled
    setIsDisabled(val) {
      if (val == "Other") {
        this.paymentInformation.typeOfAccount = "";
        this.paymentInformation.bankAccountNumber = "";

        this.additionalInformation.employmentStatus = "";
        this.additionalInformation.nextIncomeDate.day = "";
        this.additionalInformation.employerName = "";
        this.additionalInformation.workPhone = "";
        this.additionalInformation.position = "";
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },

    // bankingDetails
    async bankingDetails() {
      if (!this.bankingDetailsWasSend) {
        await this.sendBankingDetails();
      }

      /* if (
        this.paymentInformation.bank == "Standard Bank" &&
        this.paymentInformation.bankAccountNumber == "123456789"
      ) {
        this.completeStep2();
      } else */

      if (!this.verifyBankAccountHolderWasSend && this.bankingDetailsWasSend) {
        await this.verifyBankAccountHolder();
      }
    },

    // Send Banking Details
    async sendBankingDetails() {
      this.overlay = true;

      var typeOfAccount = "Savings";

      if (this.paymentInformation.typeOfAccount == "Current") {
        typeOfAccount = "Cheque/Current";
      }

      try {
        const res = await this.axios.post("bank-detail", {
          idnumber: this.getIdNumber(),
          session_key: this.getSessionKey(),
          bank_name: this.paymentInformation.bank,
          bank_account_type: typeOfAccount,
          bank_account_number: this.paymentInformation.bankAccountNumber,
        });

        if (res.status == 200) {
          this.bankingDetailsWasSend = true;

          // await this.verifyBankAccountHolder();

          this.overlay = false;

          this.completeStep2();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }
          if ("bank_name" in data) {
            this.paymentInformation.bankHasError = true;
            this.paymentInformation.bankErrorMessage = data.bank_name[0];
          }

          if ("bank_account_type" in data) {
            this.paymentInformation.typeOfAccountHasError = true;
            this.paymentInformation.typeOfAccountErrorMessage =
              data.bank_account_type[0];
          }

          if ("bank_account_number" in data) {
            this.paymentInformation.bankAccountNumberHasError = true;
            this.paymentInformation.bankAccountNumberErrorMessage =
              data.bank_account_number[0];
          }

          this.$refs.formStep2.validate();
          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // Send Employment Details
    async sendEmploymentDetails() {
      // this.overlay = true;

      try {
        const res = await this.axios.post("employment-detail", {
          idnumber: this.getIdNumber(),
          session_key: this.getSessionKey(),
          employment_type: this.additionalInformation.employmentStatus,
          occupation: this.additionalInformation.position,
          employer_name: this.additionalInformation.employerName,
          employer_contact_number: this.additionalInformation.workPhone,
          salary_day: this.additionalInformation.nextIncomeDate.day,
          salary_frequency: this.additionalInformation.frequencyOfIncome,
        });

        if (res.status == 200) {
          this.employmentDetailsWasSend = true;
          this.completeStep2();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }

          if ("employment_type" in data) {
            this.additionalInformation.employmentStatusHasError = true;
            this.additionalInformation.employmentStatusErrorMessage =
              data.employment_type[0];
          }

          if ("occupation" in data) {
            this.additionalInformation.positionHasError = true;
            this.additionalInformation.positionErrorMessage =
              data.occupation[0];
          }

          if ("employer_name" in data) {
            this.additionalInformation.employerNameHasError = true;
            this.additionalInformation.employerNameErrorMessage =
              data.employer_name[0];
          }

          if ("employer_contact_number" in data) {
            this.additionalInformation.workPhoneHasError = true;
            this.additionalInformation.workPhoneErrorMessage =
              data.employer_contact_number[0];
          }

          if ("salary_day" in data) {
            this.additionalInformation.nextIncomeDate.dayHasError = true;
            this.additionalInformation.nextIncomeDate.dayErrorMessage =
              data.salary_day[0];
          }

          if ("salary_frequency" in data) {
            this.additionalInformation.frequencyOfIncomeHasError = true;
            this.additionalInformation.frequencyOfIncomeErrorMessage =
              data.salary_frequency[0];
          }

          this.$refs.formStep2.validate();
          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      // this.overlay = false;
    },

    // Verify bank account holder
    async verifyBankAccountHolder() {
      this.overlay = true;

      try {
        const res = await this.axios.post("verify-bank-account-holder", {
          idnumber: this.getIdNumber(),
          session_key: this.getSessionKey(),
        });

        if (res.status == 200) {
          console.log("200 Data", res.data);

          this.verifyBankAccountHolderWasSend = true;
          this.completeStep2();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 401) {
          this.logOutUser();
        } else if (status == 422) {
          // console.log("422 Data", data);

          if ("idnumber" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.idnumber[0],
            });
          }

          if ("id_number" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.id_number[0],
            });
          }

          if ("result" in data) {
            this.paymentInformation.bankAccountNumberHasError = true;
            this.paymentInformation.bankAccountNumberErrorMessage =
              data.result[0];
          }

          this.$refs.formStep2.validate();
          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.step-2 {
  @media only screen and (max-width: 425px) {
    .b-button {
      width: 100%;
    }

    .heading-h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
    }
  }
}
</style>

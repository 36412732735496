<template>
  <div class="step-1">
    <v-container class="pa-0 mt-sm-4 mt-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-form ref="formStep1" lazy-validation>
            <v-row>
              <v-col cols="12">
                <h3 class="heading-h3">Registration</h3>
                <!-- Personal Details -->
                <p class="b-body-1 mt-1">
                  Step 1 of 3:
                  <span class="b-subtitle-1">Personal Details</span>
                </p>

                <div class="d-flex">
                  <v-card
                    color="primary"
                    width="154.67"
                    height="4"
                    elevation="0"
                  >
                  </v-card>
                  <v-card
                    class="ml-2"
                    color="gray400"
                    width="154.67"
                    height="4"
                    elevation="0"
                  >
                  </v-card>
                  <v-card
                    class="ml-2"
                    color="gray400"
                    width="154.67"
                    height="4"
                    elevation="0"
                  >
                  </v-card>
                </div>
              </v-col>

              <!-- alreadyRegistered -->
              <v-col v-if="alreadyRegistered" cols="12">
                <div class="warning50 mx-n6">
                  <v-card
                    class="transparent px-6 py-3"
                    width="100%"
                    max-height="111"
                    flat
                    tile
                  >
                    <h5 class="heading-h5 warning500--text py-0 my-0">
                      A user with such data is already registered
                    </h5>

                    <p class="b-body-2 warning600--text py-0 my-0">
                      <router-link
                        class="warning600--text"
                        :to="{ name: 'LoginPage' }"
                      >
                        Log
                      </router-link>
                      in to your account or contact support
                      <a
                        href="mailto:support@lime24.co.za"
                        class="warning600--text"
                        target="_blank"
                      >
                        support@lime24.co.za
                      </a>
                    </p>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" lg="4">
                <v-row>
                  <!-- Personal information -->
                  <v-col cols="12">
                    <h5 class="heading-h4">Personal information</h5>
                  </v-col>

                  <!-- Personal information -->
                  <v-col class="mt-n3" cols="12">
                    <!-- First name -->
                    <v-card width="362" flat>
                      <p class="label-text mb-1">First name</p>

                      <v-text-field
                        class="py-0 my-0"
                        v-model="name"
                        :rules="nameRules"
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      >
                      </v-text-field>
                    </v-card>

                    <!-- Surname -->
                    <v-card class="mt-2" width="362" flat>
                      <p class="label-text mb-1">Surname</p>

                      <v-text-field
                        v-model="surname"
                        :rules="surnameRules"
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      >
                      </v-text-field>
                    </v-card>

                    <!-- ID Number -->
                    <v-card class="mt-2" max-width="170" flat>
                      <p class="label-text mb-1">ID number</p>

                      <v-text-field
                        v-model="idNumber"
                        :rules="idNumberRules"
                        class="inputPrice"
                        type="number"
                        pattern="/^-?\d+\.?\d*$/"
                        onKeyPress="if(this.value.length==13) return false;"
                        onkeydown="return event.keyCode !== 69"
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>

                    <!-- Race  -->
                    <v-card class="mt-2" width="362" flat>
                      <p class="label-text mb-1">Race</p>

                      <v-select
                        v-model="race"
                        :rules="raceRules"
                        :items="getRacesList"
                        placeholder="Select..."
                        height="48px"
                        full-width
                        outlined
                        dense
                      ></v-select>

                      <p class="b-body-2 mt-n5 gray700--text">
                        For statistic purpose only
                      </p>
                    </v-card>

                    <!-- Province -->
                    <v-card class="mt-2" width="362" flat>
                      <p class="label-text mb-1">Province</p>

                      <v-select
                        v-model="province"
                        :rules="provinceRules"
                        :items="getProvincesList"
                        placeholder="Select..."
                        height="48px"
                        full-width
                        outlined
                        dense
                      ></v-select>
                    </v-card>
                  </v-col>

                  <!-- Сontacts -->
                  <v-col cols="12">
                    <h5 class="heading-h4">Сontacts</h5>
                  </v-col>

                  <!-- Сontacts -->
                  <v-col cols="12">
                    <!-- Mobile Phone -->
                    <v-card class="mt-n4" max-width="170" flat>
                      <p class="label-text mb-1">Mobile Phone</p>

                      <v-text-field
                        v-model="mobilePhone"
                        :rules="mobilePhoneRules"
                        maxlength="12"
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>

                    <!--  E-mail  -->
                    <v-card class="mt-2" width="362" flat>
                      <p class="label-text mb-1">E-mail</p>

                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>
                  </v-col>

                  <!-- Create a password -->
                  <v-col cols="12">
                    <h5 class="heading-h4">Create a password</h5>
                  </v-col>

                  <!-- Password -->
                  <v-col cols="12">
                    <v-card class="mt-n4" width="362" flat>
                      <p class="label-text mb-1">Password</p>

                      <v-text-field
                        @click:append="showPassword = !showPassword"
                        v-model="password"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="passwordRules"
                        height="48px"
                        full-width
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-card>

                    <!-- Password Rulles -->
                    <v-card class="mt-n4" width="362" flat>
                      <!-- Password Rule 1 -->
                      <p
                        class="b-body-2 success--text"
                        v-if="passwordRule1Correct"
                      >
                        <v-icon size="22" class="ma-n0" color="success">
                          mdi-check
                        </v-icon>

                        <span class="ml-1">8 to 30 characters</span>
                      </p>
                      <p class="b-body-2" v-else>
                        <v-icon large class="ma-n3">mdi-circle-small</v-icon>
                        <span class="ml-1">8 to 30 characters</span>
                      </p>

                      <!-- Password Rule 2 -->
                      <p
                        class="b-body-2 mt-n3 success--text"
                        v-if="passwordRule2Correct"
                      >
                        <v-icon size="22" class="ma-n0" color="success">
                          mdi-check
                        </v-icon>

                        <span class="ml-1"
                          >Сontains lowercase and uppercase English letters
                        </span>
                      </p>
                      <p class="b-body-2 mt-n3" v-else>
                        <v-icon large class="ma-n3">mdi-circle-small</v-icon>
                        <span class="ml-1"
                          >Сontains lowercase and uppercase English
                          letters</span
                        >
                      </p>

                      <!-- Password Rule 3 -->
                      <p
                        class="b-body-2 mt-n3 success--text"
                        v-if="passwordRule3Correct"
                      >
                        <v-icon size="22" class="ma-n0" color="success">
                          mdi-check
                        </v-icon>

                        <span class="ml-1">Сontains letters and numbers</span>
                      </p>
                      <p class="b-body-2 mt-n3" v-else>
                        <v-icon large class="ma-n3">mdi-circle-small</v-icon>
                        <span class="ml-1">Сontains letters and numbers</span>
                      </p>
                    </v-card>
                  </v-col>

                  <!-- agreeTo -->
                  <v-col cols="12">
                    <!-- agreeToTermsAndConditions -->
                    <v-card class="mt-n11" width="362" flat>
                      <v-checkbox
                        v-model="agreeToTermsAndConditions"
                        :rules="agreeToTermsAndConditionsRules"
                        label="Please agree with our Terms and Conditions."
                        required
                        class="gray800--text"
                      >
                        <template v-slot:label>
                          <div class="b-body-1">
                            Please agree with our
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a
                                  class="text-no-wrap"
                                  target="_blank"
                                  :href="termsAndConditionsUrl"
                                  @click.stop
                                  v-on="on"
                                >
                                  Terms and Conditions
                                </a>
                              </template>
                              Opens in new window
                            </v-tooltip>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-card>

                    <!-- agreeToTermsAndConditions -->
                    <v-card class="mt-n9" width="362" flat>
                      <v-checkbox
                        v-model="agreeToPrivacyPolicy"
                        :rules="agreeToPrivacyPolicyRules"
                        label="Please agree with our Privacy policy"
                        required
                        class="gray800--text"
                      >
                        <template v-slot:label>
                          <div class="b-body-1">
                            Please agree with our
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a
                                  class="text-no-wrap"
                                  target="_blank"
                                  :href="privacyPolicyUrl"
                                  @click.stop
                                  v-on="on"
                                >
                                  Privacy policy
                                </a>
                              </template>
                              Opens in new window
                            </v-tooltip>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-card>
                  </v-col>

                  <!-- Next -->
                  <!-- Desktop -->
                  <v-col cols="12" class="hidden-xs-only mt-n2">
                    <v-btn
                      @click="validateFormStep1"
                      class="b-button primary text-capitalize"
                      height="48"
                      width="175"
                      elevation="0"
                      :disabled="!readyToSubmit"
                    >
                      Next
                    </v-btn>
                  </v-col>

                  <!-- Mobile -->
                  <v-col cols="12" class="hidden-sm-and-up mt-n2">
                    <v-btn
                      @click="validateFormStep1"
                      class="b-button primary text-capitalize"
                      height="48"
                      width="100%"
                      elevation="0"
                      :disabled="!readyToSubmit"
                    >
                      Next
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>

          <!-- Overlay -->
          <v-overlay :value="overlay" z-index="1000">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "LimeLoansStep1",

  data() {
    return {
      overlay: false,
      readyToSubmit: false,
      readyToSubmit2: false,
      alreadyRegistered: false,

      privacyPolicyUrl: process.env.VUE_APP_APP_URL + "/privacy-policy",
      termsAndConditionsUrl:
        process.env.VUE_APP_APP_URL +
        "/assets/docs/Lime Loans South Africa Website Terms and Conditions.pdf",

      // Name
      name: "",
      nameRules: [
        (v) => !!v || "Required",
        (v) => (v && !this.nameHasError) || this.nameErrorMessage,
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length <= 50 || "Can not be more than 50 characters",
      ],
      nameHasError: false,
      nameErrorMessage: "name",

      // Title
      title: "",
      titleRules: [
        (v) => !!v || "Required",
        (v) => (v && !this.titleHasError) || this.titleErrorMessage,
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
      ],
      titleHasError: false,
      titleErrorMessage: "title",

      // Surname
      surname: "",
      surnameRules: [
        (v) => !!v || "Required",
        (v) => (v && !this.surnameHasError) || this.surnameErrorMessage,
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length <= 25 || "Can not be more than 25 characters",
      ],
      surnameHasError: false,
      surnameErrorMessage: "surname",

      // ID Number
      idNumber: "",
      idNumberRules: [
        (v) => !!v || "Required",
        (v) => (v && !this.idNumberHasError) || this.idNumberErrorMessage,
        (v) => /[0-9]{13}/.test(v) || "Id Number must be 13 numbers",
        (v) => v.length == 13 || "Id Number must be 13 numbers",
      ],
      idNumberHasError: false,
      idNumberErrorMessage: "Id Number",

      // Race
      race: "",
      raceRules: [
        (v) => !!v || "",
        (v) => (v && !this.raceHasError) || this.raceErrorMessage,
      ],
      raceHasError: false,
      raceErrorMessage: "race",

      // Province
      province: "",
      provinceRules: [
        (v) => !!v || "Required",
        (v) => (v && !this.provinceHasError) || this.provinceErrorMessage,
      ],
      provinceHasError: false,
      provinceErrorMessage: "province",

      // Mobile Phone
      mobilePhone: "",
      mobilePhoneRules: [
        (v) => !!v || "Required",
        (v) => (v && !this.mobilePhoneHasError) || this.mobilePhoneErrorMessage,
        (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
        (v) => v.length == 12 || "Must be 10 numbers",
      ],
      mobilePhoneHasError: false,
      mobilePhoneErrorMessage: "mobile phone",

      // Email
      email: "",
      emailRules: [
        (v) => !!v || "Required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
        (v) => (v && !this.emailHasError) || this.emailErrorMessage,
      ],
      emailHasError: false,
      emailErrorMessage: "email",

      // Password
      password: "",
      passwordRules: [
        (v) => !!v || "",
        (v) => (v && !this.passwordHasError) || this.passwordErrorMessage,
        (v) => v.length >= 8 || "",
        (v) => v.length <= 30 || "",
        (v) => /^(?=.*?[A-Z])/.test(v) || "",
        (v) => /^(?=.*?[a-z])/.test(v) || "",
        (v) => /^(?=.*?[0-9])/.test(v) || "",
      ],
      passwordHasError: false,
      passwordErrorMessage: "password",

      passwordRule1Correct: false,
      passwordRule2Correct: false,
      passwordRule3Correct: false,

      // Show Password
      showPassword: false,

      // Agree To Terms And Conditions
      agreeToTermsAndConditions: false,
      agreeToTermsAndConditionsRules: [
        (v) => !!v || "",
        // (v) => !!v || "You need to accept the Terms And Conditions",
      ],

      // Agree To Privacy policy
      agreeToPrivacyPolicy: false,
      agreeToPrivacyPolicyRules: [
        (v) => !!v || "",
        // (v) => !!v || "You need to accept the Privacy Policy",
      ],
    };
  },

  created() {
    // this.listSetDefaultState();

    // let parameters = this.$route.query;

    // console.log("parameters", parameters);

    // checkForMarketingQuery
    /* if (this.checkForMarketingQuery(parameters)) {
      this.resetMarketingState();

      this.$cookies.remove("traffic_source_data");

      // console.log("parameters @@@", parameters);

      this.setForMarketingQuery(parameters);

      // marketingSetSessionExpires
      this.marketingSetSessionExpires();
    } */

    this.$helpers.resetErrorHandlerState();
  },

  computed: {
    ...mapGetters({
      getProvincesList: "list/getProvincesList",
      getRacesList: "list/getRacesList",
    }),

    ...mapState({ auth: "auth", list: "list", marketing: "marketing" }),
  },

  watch: {
    // name
    name() {
      if (this.nameHasError) {
        this.nameHasError = false;
        this.nameErrorMessage = "";
      }

      this.setReadyToSubmit();
      // this.setName(this.name);
    },

    // surname
    surname() {
      if (this.surnameHasError) {
        this.surnameHasError = false;
        this.surnameErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // race
    race() {
      if (this.raceHasError) {
        this.raceHasError = false;
        this.raceErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // idNumber
    idNumber() {
      if (this.idNumberHasError) {
        this.idNumberHasError = false;
        this.idNumberErrorMessage = "";

        this.mobilePhoneHasError = false;
        this.emailHasError = false;
      }

      if (this.alreadyRegistered) {
        this.alreadyRegistered = false;
      }

      this.setReadyToSubmit();
    },

    // province
    province() {
      if (this.provinceHasError) {
        this.provinceHasError = false;
        this.provinceErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // mobilePhone
    mobilePhone(val) {
      if (this.mobilePhoneHasError) {
        this.mobilePhoneHasError = false;
        this.mobilePhoneErrorMessage = "";
      }

      this.mobilePhone = this.$helpers.formatInputMobileNumber(val);

      this.setReadyToSubmit();
    },

    // email
    email() {
      if (this.emailHasError) {
        this.emailHasError = false;
        this.emailErrorMessage = "";
      }

      this.setReadyToSubmit();
    },

    // password
    password(val) {
      if (this.passwordHasError) {
        this.passwordHasError = false;
        this.passwordErrorMessage = "";
      }

      this.validatePassword(val);

      this.setReadyToSubmit();
    },

    // agreeToTermsAndConditions
    agreeToTermsAndConditions() {
      this.setReadyToSubmit();
    },

    // agreeToPrivacyPolicy
    agreeToPrivacyPolicy() {
      this.setReadyToSubmit();
    },
  },

  mounted() {},

  methods: {
    ...mapActions({
      getApiUserDetails: "auth/getApiUserDetails",

      logOutUser: "auth/logOutUser",
    }),

    ...mapGetters({
      getIdNumber: "auth/getIdNumber",
      getSessionKey: "auth/getSessionKey",
      getSessionExpires: "auth/getSessionExpires",
      getCurrentState: "registration/getCurrentState",

      marketingCheckSessionExpired: "marketing/checkSessionExpired",
    }),

    ...mapMutations({
      setLoggedIn: "auth/setLoggedIn",
      setSessionKey: "auth/setSessionKey",
      setSessionExpires: "auth/setSessionExpires",
      setUser: "auth/setUser",
      setIdNumber: "auth/setIdNumber",

      setCurrentState: "registration/setCurrentState",
      listSetDefaultState: "list/setDefaultState",

      resetTruidState: "truid/resetState",

      // marketing
      setLeadId: "marketing/setLeadId",
      setUTMSource: "marketing/setUTMSource",
      setUTMMedium: "marketing/setUTMMedium",
      setUTMCampaign: "marketing/setUTMCampaign",
      setUTMContent: "marketing/setUTMContent",
      setUTMTerm: "marketing/setUTMTerm",
      setUTMWebmasterId: "marketing/setUTMWebmasterId",
      setClickId: "marketing/setClickId",
      setGClid: "marketing/setGClid",
      setMindboxClickId: "marketing/setMindboxClickId",

      marketingSetSessionExpires: "marketing/setSessionExpires",

      resetMarketingState: "marketing/resetState",
    }),

    // validatePassword
    validatePassword(password) {
      // 8 to 30 characters
      if (8 <= password.length && password.length <= 30) {
        this.passwordRule1Correct = true;
      } else {
        this.passwordRule1Correct = false;
      }

      // Сontains lowercase and uppercase English letters
      if (/[A-Z]/.test(password) && /[a-z]/.test(password)) {
        this.passwordRule2Correct = true;
      } else {
        this.passwordRule2Correct = false;
      }

      // Сontains letters and numbers
      if (/[A-Za-z]/.test(password) && /[0-9]/.test(password)) {
        this.passwordRule3Correct = true;
      } else {
        this.passwordRule3Correct = false;
      }
    },

    // setReadyToSubmit
    setReadyToSubmit() {
      if (
        this.name === "" ||
        this.name === null ||
        this.surname === "" ||
        this.surname === null ||
        this.race === "" ||
        this.race === null ||
        this.idNumber === "" ||
        this.idNumber === null ||
        this.province === "" ||
        this.province === null ||
        this.mobilePhone === "" ||
        this.mobilePhone === null ||
        this.email === "" ||
        this.email === null ||
        this.password === "" ||
        this.agreeToTermsAndConditions === false ||
        this.agreeToPrivacyPolicy === false ||
        !this.$refs.formStep1.validate()
      ) {
        this.readyToSubmit = false;
      } else {
        this.readyToSubmit = true;
      }
    },

    // validateFormStep1
    validateFormStep1() {
      this.$refs.formStep1.validate();

      if (this.$refs.formStep1.validate()) {
        this.registerUser();

        // this.validate();
      }
    },

    validate() {
      this.setCurrentState("step2");

      if (this.$gtm.enabled()) {
        window.dataLayer?.push({
          event: "registration",
          eventParam: "first_step_done",
        });
      }

      this.$vuetify.goTo(0);
    },

    // Register
    async registerUser() {
      this.overlay = true;

      try {
        const historically_disadvantaged = this.race == "White" ? "0" : "1";

        this.setTitle();

        var reqData = {
          idnumber: this.idNumber,
          title: this.title,
          firstnames: this.name,
          surname: this.surname,
          historically_disadvantaged: historically_disadvantaged,
          cellular: this.mobilePhone.replaceAll(" ", ""),
          email: this.email,
          province: this.province,
          password: this.password,
          password_verify: this.password,
        };

        if (this.marketingCheckSessionExpired()) {
          this.resetMarketingState();

          this.$cookies.remove("traffic_source_data");
        } else {
          reqData = await this.addMarketingToReq(reqData);
        }

        // console.log("reqData", reqData);

        const res = await this.axios.post("register", reqData);

        const data = res.data;

        // console.log(data);

        if (res.status === 200) {
          this.setSessionKey(data.session_key);

          // var now = new Date();
          // now.setMinutes(now.getMinutes() + 3); // timestamp
          // now = new Date(now); // Date object

          // this.setSessionExpires(now);

          this.setSessionExpires(data.session_expires);

          this.setIdNumber(this.idNumber);

          await this.getApiUserDetails();

          this.setLoggedIn(true);

          this.resetTruidState();

          this.validate();
        }
      } catch (error) {
        let data = {};

        if (error && error.response && error.response.data) {
          data = error.response.data;
        }

        const status =
          error && error.response && error.response.status
            ? error.response.status
            : 500;

        if (status == 422) {
          if ("idnumber" in data) {
            this.idNumberHasError = true;
            this.idNumberErrorMessage = data.idnumber[0];

            if (data.idnumber[0] == "Client previously registered") {
              this.alreadyRegistered = true;
              this.idNumberErrorMessage = data.idnumber[0];
            }
          }

          if ("title" in data) {
            this.titleHasError = true;
            this.titleErrorMessage = data.title[0];
          }

          if ("firstnames" in data) {
            this.nameHasError = true;
            this.nameErrorMessage = data.firstnames[0];
          }

          if ("surname" in data) {
            this.surnameHasError = true;
            this.surnameErrorMessage = data.surname[0];
          }

          if ("historically_disadvantaged" in data) {
            this.raceHasError = true;
            this.raceErrorMessage = data.historically_disadvantaged[0];
          }

          if ("cellular" in data) {
            this.mobilePhoneHasError = true;
            this.mobilePhoneErrorMessage = data.cellular[0];
          }

          if ("email" in data) {
            this.emailHasError = true;
            this.emailErrorMessage = data.email[0];
          }

          if ("province" in data) {
            this.provinceHasError = true;
            this.provinceErrorMessage = data.province[0];
          }

          if ("password" in data) {
            this.passwordHasError = true;
            this.passwordErrorMessage = data.password[0];
          }

          if ("password_verify" in data) {
            this.confirmPasswordHasError = true;
            this.confirmPasswordErrorMessage = data.password_verify[0];
          }

          if (this.$refs.formStep1) {
            this.$refs.formStep1.validate();
          }

          this.setReadyToSubmit();

          this.$swal({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Please check for errors on the form.",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          this.$helpers.handleError(status, data);
        }
      }

      this.overlay = false;
    },

    // Set title
    setTitle() {
      // Check if a user is a female or male
      // We use the 7th digit on the Id number if number is 0 - 4 Female else if number is 5 - 9 Male

      const numberString = this.idNumber[6];
      const number = Number(numberString);

      if (0 <= number && number <= 4) this.title = "Ms";
      else if (5 <= number && number <= 9) this.title = "Mr";
    },

    // checkForMarketingQuery
    checkForMarketingQuery(parameters) {
      if (
        "lead_id" in parameters ||
        "utm_source" in parameters ||
        "utm_medium" in parameters ||
        "utm_campaign" in parameters ||
        "utm_content" in parameters ||
        "utm_term" in parameters ||
        "webmaster_id" in parameters ||
        "clickid" in parameters ||
        "gclid" in parameters ||
        "mindbox_click_id" in parameters
      ) {
        return true;
      }

      return false;
    },

    // setForMarketingQuery
    setForMarketingQuery(parameters) {
      // lead_id
      if ("lead_id" in parameters) {
        this.setLeadId(parameters.lead_id);
      }

      // utm_source
      if ("utm_source" in parameters) {
        this.setUTMSource(parameters.utm_source);
      }

      // utm_medium
      if ("utm_medium" in parameters) {
        this.setUTMMedium(parameters.utm_medium);
      }

      // utm_campaign
      if ("utm_campaign" in parameters) {
        this.setUTMCampaign(parameters.utm_campaign);
      }

      // utm_content
      if ("utm_content" in parameters) {
        this.setUTMContent(parameters.utm_content);
      }

      // utm_term
      if ("utm_term" in parameters) {
        this.setUTMTerm(parameters.utm_term);
      }

      // webmaster_id
      if ("webmaster_id" in parameters) {
        this.setUTMWebmasterId(parameters.webmaster_id);
      }

      // clickid
      if ("clickid" in parameters) {
        this.setClickId(parameters.clickid);
      }

      // gclid
      if ("gclid" in parameters) {
        this.setGClid(parameters.gclid);
      }

      // mindbox_click_id
      if ("mindbox_click_id" in parameters) {
        this.setMindboxClickId(parameters.mindbox_click_id);
      }
    },

    // addMarketingToReq
    async addMarketingToReq(reqData) {
      // lead_id
      if (this.marketing.lead_id != "") {
        reqData.lead_id = this.marketing.lead_id;
      }

      // utm_source
      if (this.marketing.utm_source != "") {
        reqData.utm_source = this.marketing.utm_source;

        reqData.ip_address = await this.getIPAddress();
        reqData.user_agent = await this.getUserAgent();
      }

      // utm_medium
      if (this.marketing.utm_medium != "") {
        reqData.utm_medium = this.marketing.utm_medium;
      }

      // utm_campaign
      if (this.marketing.utm_campaign != "") {
        reqData.utm_campaign = this.marketing.utm_campaign;
      }

      // utm_content
      if (this.marketing.utm_content != "") {
        reqData.utm_content = this.marketing.utm_content;
      }

      // utm_term
      if (this.marketing.utm_term != "") {
        reqData.utm_term = this.marketing.utm_term;
      }

      // webmaster_id
      if (this.marketing.webmaster_id != "") {
        reqData.webmaster_id = this.marketing.webmaster_id;
      }

      // clickid
      if (this.marketing.clickid != "") {
        reqData.clickid = this.marketing.clickid;
      }

      // gclid
      if (this.marketing.gclid != "") {
        reqData.gclid = this.marketing.gclid;
      }

      // mindbox_click_id
      if (this.marketing.mindbox_click_id != "") {
        reqData.mindbox_click_id = this.marketing.mindbox_click_id;
      }

      return reqData;
    },

    // getIPAddress
    async getIPAddress() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        const ipAddress = data.ip;

        return ipAddress;
      } catch (error) {
        console.error("Error fetching IP address: " + error.message);

        return "127.0.0.1";
      }
    },

    // getUserAgent
    getUserAgent() {
      return navigator.userAgent;
    },
  },
};
</script>

<style lang="scss" scoped>
.step-1 {
  @media only screen and (max-width: 425px) {
    .b-button {
      width: 100%;
    }
    .heading-h4 {
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
    }
  }
}
</style>
